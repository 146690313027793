/* src/App.css */

.App {
  text-align: center;
}

.content {
  padding: 20px;
}

.intro {
  margin-bottom: 40px;
}

.intro h1 {
  font-size: 36px;
  margin-bottom: 10px;
}

.intro p {
  font-size: 18px;
  color: #555;
}

.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.service-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  width: calc(33% - 40px);
  box-sizing: border-box;
  transition: transform 0.3s;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-card h3 {
  font-size: 24px;
  color: #141b1a;
  margin-bottom: 10px;
}

.service-card p {
  font-size: 16px;
  color: #555;
}

.footer {
  padding: 20px;
  background-color: #3f51b5;
  color: white;
  margin-top: 40px;
}

.footer nav a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.footer nav a:hover {
  text-decoration: underline;
}
