/* src/Contact.css */
.contact {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.contact h1 {
  margin-bottom: 20px;
}

.contact p {
  margin-bottom: 20px;
}

.contact form {
  display: flex;
  flex-direction: column;
}

.contact .form-group {
  margin-bottom: 15px;
}

.contact label {
  display: block;
  margin-bottom: 5px;
}

.contact input,
.contact textarea {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.contact textarea {
  height: 150px; /* Adjust this value to make the textarea taller */
}

.contact input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}

.contact button {
  padding: 10px 15px;
  background-color: #cfccc2;
  color: #141b1a;
  border: none;
  cursor: pointer;
}

.contact button:hover {
  background-color: #0056b3;
}

.contact p {
  margin-top: 20px;
}
