/* src/About.css */
.about {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    line-height: 1.6;
  }
  
  .about-content {
    text-align: left; /* Add this line */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .about-content h1,
  .about-content h2 {
    color: #141b1a;
    margin-bottom: 10px;
  }
  
  .about-content p {
    margin-bottom: 20px;
  }
  
  .about-content ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .about-content li {
    margin-bottom: 10px;
  }
  
  @media (max-width: 600px) {
    .about-content {
      padding: 10px;
    }
  }
  